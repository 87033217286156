<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;
		background: #f5f5f5;

		.width1200 {
			margin: 0 auto;
		}

		.title {
			font-size: 40px;
			font-weight: bold;
			padding: 30px 0;
			text-align: center;
			width: 100%;
		}

		.titleTip {
			font-size: 16px;
			color: #919191;
			text-align: center;
			width: 100%;
			padding-bottom: 30px;
		}

		@keyframes slide-top {
			0% {
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}

			100% {
				-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
			}
		}

		.myBox {
			background: #fff;
			border: #d0d0d0 solid 1px;
			margin-bottom: 20px;
			border-radius: 20px;
			padding: 30px;

			&_title {
				font-size: 20px;
				font-weight: bold;
			}

			&_nr {
				font-size: 16px;
				color: #919191;
				padding-top: 10px;
			}
		}

		.myBox:hover {
			animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		}
	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="title">我们谈谈？</div>
			<div class="titleTip">如果您有更多的问题，请联系我们！</div>
<!--			<div class="myBox">-->
<!--				<div class="myBox_title">WECHAT</div>-->
<!--				<div class="myBox_nr">微信：huawei-noe</div>-->
<!--				<div class="myBox_nr">WhatsApp：+85292051883</div>-->
<!--			</div>-->
			<div class="myBox">
				<div class="myBox_title">TELEGRAM</div>
        <div class="myBox_nr"><a href="https://web.telegram.org/a/#7377896108" target="_blank" style="text-decoration: none">@HaiBaoAppbBot 海豹智能客服 </a></div>
				<div class="myBox_nr"><a href="https://web.telegram.org/a/#7476097715" target="_blank" style="text-decoration: none">@HaibaoApp 海豹官方频道</a></div>
				<div class="myBox_nr"><a href="https://web.telegram.org/a/#7351844966" target="_blank" style="text-decoration: none">@HaibaoSCRMexe 海豹官方人工客服</a></div>
				<div class="myBox_nr"><a href="https://web.telegram.org/a/#7322372271" target="_blank" style="text-decoration: none">@HaiBaoTGA 海豹大客户合作专线</a></div>
			</div>
<!--			<div class="myBox">-->
<!--				<div class="myBox_title">OUR EMAIL</div>-->
<!--				<div class="myBox_nr">HaiBaoscrmapp@gmail.com</div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>
