<style lang="less" scoped>
	.cont {
		height: 700px;
		background: linear-gradient(to top right, #081799 5%, #7d2dfb 60%, #be2fff);
		display: flex;
		align-items: center;
		justify-content: center;

		&_box {
			display: flex;
			align-items: center;

			.left {
				color: #fff;
				max-width: 600px;

				.title {
					font-size: 60px;
				}

				.tip {
					font-size: 18px;
					padding-top: 20px;
					line-height: 40px;
				}

				.btn {
					display: flex;
					align-items: center;
					padding-top: 30px;

					.btnBox {
						padding: 20px 30px;
						background-color: #ffcd57;
						color: #333;
						font-size: 20px;
						border-radius: 30px;
						margin-right: 20px;
						font-weight: bold;
						cursor: pointer;
						display: flex;
						align-items: center;

						img {
							width: 20px;
							height: 20px;
							margin-right: 10px;
						}
					}

					.btnBox:hover {
						animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
					}

					@keyframes slide-top {
						0% {
							-webkit-transform: translateY(0);
							transform: translateY(0);
						}

						100% {
							-webkit-transform: translateY(-10px);
							transform: translateY(-10px);
						}
					}

				}
			}

			.right {
				img {
					width: 600px;
					height: 600px;
				}

				img:hover {
					transform: scale(1.1);
				}
			}
		}

	}
</style>
<template>
	<div class="cont">
		<div class="cont_box width1200">
			<div class="left">
				<div class="title">海豹SCRM</div>
				<div class="title">跨境电商应用聚合平台</div>
				<div class="tip">多合一聚合便捷聊天 • 多平台引流计数统计 • 谷歌实时双向翻译 • 智能快捷多模式回复</div>
				<div class="btn">
					<div class="btnBox">
            <a href="https://hbplus.oss-ap-southeast-3.aliyuncs.com/hb-scrm.exe" target="_blank" style="text-decoration: none; color: black">
						<img src="@/assets/windows.png" />
						windows
            </a>
					</div>
					<div class="btnBox">
            <a href="https://hbplus.oss-ap-southeast-3.aliyuncs.com/hb-scrm.exe" target="_blank" style="text-decoration: none; color: black">
						<img src="@/assets/mac.png" />
						macos
            </a>
					</div>
				</div>
			</div>
			<div class="right">
				<img src="@/assets/head.png" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				vheight: 0,
			}
		},
		onLoad() {
			// 计算屏幕剩余高度  填补剩余高度
			let _this = this;
			uni.getSystemInfo({
				success(res) {
					console.log(res)
					_this.vheight = res.windowHeight;
				}
			});
		},
		methods: {

		}
	}
</script>
