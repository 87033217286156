<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;

		.width1200 {
			margin: 0 auto;
		}

		.title {
			font-size: 40px;
			font-weight: bold;
			padding: 30px 0;
			text-align: center;
			width: 100%;
		}

		.titleTip {
			font-size: 16px;
			color: #919191;
			text-align: center;
			width: 100%;
			padding-bottom: 30px;
		}

		@keyframes slide-top {
			0% {
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}

			100% {
				-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
			}
		}

		.myBox {
			display: flex;
			align-items: center;
			padding: 30px;

			img {
				width: 600px;
				height: 300px;
			}

			&_cont {
				box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
				margin-right: 30px;
				padding: 30px;
				border-radius: 10px;

				&_title {
					font-size: 22px;
					font-weight: bold;
					color: #7ed6c9;
				}

				&_value {
					font-size: 16px;
					padding-top: 20px;
					line-height: 25px;
					color: #7f7f7f;
				}
			}

			&_cont:hover {
				animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				cursor: pointer;
			}
		}


	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="title">关于我们</div>
			<div class="titleTip">全球私域运营专家</div>
			<div class="myBox">
				<div class="myBox_cont">
					<div class="myBox_cont_title">Who We Are</div>
					<div class="myBox_cont_value">
						海豹出海是全球私域运营专家，专注于全球私域营销，其业务覆盖全球8个国家，主要提供社交媒体营销自动化软件开发和咨询服务，团队扎根于马来西亚，其业务覆盖全球8个国家，欢迎咨询私域营销问题，7*24小时为您服务</div>
				</div>
				<img src="@/assets/map.png" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>