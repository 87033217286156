<template>
	<div class="home">
		<home-tabs></home-tabs>
		<home-head></home-head>
		<home-platform id="platform"></home-platform>
		<home-function id="function"></home-function>
		<home-pirce id="pirce"></home-pirce>
		<home-relation id="relation"></home-relation>
		<home-about id="about"></home-about>
		<home-bottom></home-bottom>
	</div>
</template>

<script>
	import homeTabs from '@/components/home_tabs.vue'
	import homeHead from '@/components/home_head.vue'
	import homePlatform from '@/components/home_platform'
	import homeFunction from '@/components/home_function'
	import homeRelation from '@/components/home_relation'
	import homeAbout from '@/components/home_about'
	import homeBottom from '@/components/home_bottom'
	import homePirce from '@/components/home_pirce'
	export default {
		components: {
			homeHead,
			homeTabs,
			homePlatform,
			homeFunction,
			homeRelation,
			homeAbout,
			homeBottom,
			homePirce
		}
	}
</script>

<style lang="less">
	.home {
		width: 100%;
	}
</style>