<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;
		background: linear-gradient(to top right, #081799 5%, #7d2dfb 60%, #be2fff);

		.width1200 {
			margin: 0 auto;
		}

		.title {
			font-size: 16px;
			font-weight: bold;
			padding: 30px 0;
			text-align: center;
			width: 100%;
			color: #fff;
		}
	}

	.swiper {
		display: flex;

		&_box:hover {
			animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		}

		@keyframes slide-top {
			0% {
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}

			50% {
				-webkit-transform: translateY(-5px);
				transform: translateY(-5px);
			}

			100% {
				-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
			}
		}

		&_box {
			background: #fff;
			padding: 30px;
			border-radius: 10px;
			margin-right: 10px;

			&_title {
				display: flex;
				align-items: center;
				font-weight: bold;

				.left {
					font-size: 20px;
					min-width: 60px;
				}

				.tip {
					width: 110px;
					max-width: 150px;
					height: 2px;
					background: #e3e3e3;
					margin: 0 20px;
				}

				.right {
					font-size: 17px;
					color: #8e8e8e;
					min-width: 120px;
				}
			}

			&_pirce {
				font-size: 16px;
				font-weight: bold;
				padding: 30px 0;

				span {
					font-size: 40px;
				}
			}

			&_btn {
				padding: 15px 30px;
				color: #fff;
				background: #007aff;
				width: 150px;
				text-align: center;
				border-radius: 40px;
				font-weight: bold;
				cursor: pointer;
			}

			&_list {
				padding-top: 30px;

				.box {
					color: #71747d;

					.el-icon-check {
						color: #83dea6;
						font-weight: bold;
						padding-right: 5px;
					}

					font-weight: bold;
					font-size: 16px;
					padding-bottom: 20px;
				}
			}
		}
	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="title">构建跨境电商出海，选择海豹出海，7X24小时客服，季卡8.5折，半年卡7折，年卡6折</div>
			<div class="swiper">
				<div class="swiper_box">
					<div class="swiper_box_title">
						<div class="left">字符版</div>
						<div class="tip"></div>
						<div class="right">适合个人</div>
					</div>
					<div class="swiper_box_pirce">
						<span class="value">待定</span>
						/150w字符数-不限制时间
					</div>
					<div class="swiper_box_list">
						<div class="box">
<!--							<i class="el-icon-check"></i>-->
<!--							按字符计算-->
						</div>
            <div class="box">
<!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <i class="el-icon-check"></i>
              按字符计算

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>

            <div class="swiper_box_btn">在线购买</div>
					</div>
				</div>
				<div class="swiper_box">
					<div class="swiper_box_title">
						<div class="left">标准版</div>
						<div class="tip"></div>
						<div class="right">小型企业推荐</div>
					</div>
					<div class="swiper_box_pirce">
            低至
						<span class="value">$4.5</span>
						/月/端口
					</div>
					<div class="swiper_box_list">
						<div class="box">
							<i class="el-icon-check"></i>
						5-19端口 $8/月
						</div>
						<div class="box">
							<i class="el-icon-check"></i>
              20-49端口 $7/月
						</div>
						<div class="box">
							<i class="el-icon-check"></i>
              50-199端口 $6/月
						</div>
						<div class="box">
							<i class="el-icon-check"></i>
              200-499端口 $4.5/月
						</div>

            <div class="swiper_box_btn">在线购买</div>
					</div>
				</div>
				<div class="swiper_box">
					<div class="swiper_box_title">
						<div class="left">高级版</div>
						<div class="tip"></div>
						<div class="right">中小型企业</div>
					</div>
          <div class="swiper_box_pirce">
            低至
            <span class="value">$7</span>
            /月/端口
          </div>
          <div class="swiper_box_list">
            <div class="box">
              <!--							<i class="el-icon-check"></i>-->
              <!--							按字符计算-->
            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <i class="el-icon-check"></i>
              待定

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>
            <div class="box">
              <!--              <i class="el-icon-check"></i>-->

            </div>

            <div class="swiper_box_btn">在线购买</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>
