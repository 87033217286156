<style lang="less" scoped>
	.cont {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 999;

		.width1200 {
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}

		.left {
			padding: 10px 0;

			img {
				width: 200px;
				height: 70px;
			}
		}

		.right {
			display: flex;
			font-size: 18px;
			color: #fff;

			.box {
				width: 100px;
				text-align: center;
				cursor: pointer;
				padding: 30px 0;
			}

			.box:hover {
				animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			}

			@keyframes slide-top {
				0% {
					background: rgba(0, 0, 0, 0);
				}

				100% {
					background: rgba(0, 0, 0, 0.3);
				}
			}
		}
	}

	a {
		text-decoration: none;
		color: #fff;
	}
</style>
<template>
	<div class="cont" :style="{'background':`rgba(125,45,251,${opacitybg})`}">
		<div class="width1200">
			<dic class="left">
				<img src="@/assets/logo.png" />
			</dic>
			<div class="right">
				<a :href="item.href" v-for="(item,index) in list" :key="index">
					<div class="box">
						{{item.title}}
					</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						title: '首页'
					},
					{
						title: '支持平台',
						href: '#platform'
					},
					{
						title: '功能',
						href: '#function'
					},
					// {
					// 	title: '内容'
					// },
					// {
					// 	title: '平台优势'
					// },
					{
						title: '价格',
						href: '#pirce'
					},
					{
						title: '联系方式',
						href: '#relation'
					},
					{
						title: '关于我们',
						href: '#about'
					}
				],
				opacitybg: 0
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			screenTop() {
				//锚点跳转
				// window.location.hash = "#abc"
			},
			handleScroll(e) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				this.opacitybg = scrollTop / 50 > 0.9 ? 0.9 : scrollTop / 50;
				console.log(scrollTop, this.opacitybg)
			}
		}
	}
</script>