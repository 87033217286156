<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;
		background: #040836;



		.bottom {
			border-top: #cccccc solid 1px;

			.box {
				width: 1200px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				color: #fff;
				font-size: 16px;
				justify-content: space-between;
				padding-top: 20px;
				cursor: pointer;
			}
		}

		.width1200 {
			margin: 0 auto;
			height: 400px;
			color: #fff;
			display: flex;

			.left1 {
				width: 400px;
				height: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-direction: column;

				.title {
					font-size: 40px;
					padding-bottom: 30px;
				}

				.value {
					line-height: 30px;
					font-size: 17px;
				}
			}

			.left2 {
				width: 600px;
				padding-left: 60px;
				display: flex;
				align-items: center;
				margin-left: 20px;
				position: relative;

				.box {
					width: 300px;

					.title {
						font-size: 22px;
						font-weight: bold;
					}

					.value {
						padding-top: 20px;
						cursor: pointer;
						color: #cccccc;
						font-size: 16px;
					}
				}
			}

			.left2::after {
				content: "";
				width: 1px;
				height: 200px;
				background: #c8c8c8;
				position: absolute;
				left: 0;
			}
		}
	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="left1">
				<div class="title">海豹出海</div>
				<div class="value">
					支持全球200+语种,消息接收双向在线自动实时
					精准翻译,无需复制粘贴,畅享极速交流体验
				</div>
			</div>
			<div class="left2">
				<div class="box">
					<div class="title">快速链接</div>
          <div class="value">><a href="http://admin.hb.plus" target="_blank" style="text-decoration: none; color: #d0d0d0"> 注册</a> </div>
					<div class="value">><a href="http://admin.hb.plus" target="_blank" style="text-decoration: none; color: #d0d0d0">  登录</a></div>
					<div class="value">> 在线购买</div>
				</div>
				<div class="box">
					<div class="title">帮助中心</div>
					<div class="value">> 关于我们 </div>
					<div class="value">> 使用说明</div>
					<div class="value">> 隐私说明</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="box">
				<div>@ Copyright 2022 HaiBao All Rights</div>
				<div><a href="http://admin.hb.plus" target="_blank" style="text-decoration: none; color: #d0d0d0">海豹官方 海豹导航网 海豹计数器</a></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>
