<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;
		background: #f5f5f5;

		@keyframes slide-top {
			0% {
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}

			100% {
				-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
			}
		}

		.width1200 {
			margin: 0 auto;
		}

		.title {
			font-size: 40px;
			font-weight: bold;
			padding: 30px 0;
			text-align: center;
			width: 100%;
		}

		.list {
			display: flex;
			flex-wrap: wrap;

			.img {
				width: 200px;

			}

			.box {
				background: #fff;
				width: 230px;
				margin-right: 10px;
				margin-bottom: 20px;
				padding: 20px;
			}

			.box:hover {
				animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			}

			.imgBox {
				height: 250px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.value {
				font-size: 24px;
				font-weight: bold;
				text-align: center;
				padding-bottom: 20px;
			}

			.tip {
				font-size: 16px;
				text-align: center;
				line-height: 26px;
			}

		}
	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="title">在线客服系统 多功能亮点</div>
			<div class="list">
				<div class="box" v-for="(item,index) in list" :key="index">
					<div class="imgBox">
						<img class="img" :src="item.img" />
					</div>
					<div class="value">{{item.title}}</div>
					<div class="tip">{{item.tip}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						img: require('@/assets/function1.png'),
						title: '即时聊天快速接入',
						tip: '支持聊天链接/网络插件等多方式接入，实时对话，简单方便'
					},
					{
						img: require('@/assets/illustration-9.svg'),
						title: '多语言翻译',
						tip: '我们支持超过200种语言，并整合了谷歌、微软、Deepl、亚马逊等多个翻译平台，使您能够轻松与全球的朋友和合作伙伴进行交流。'
					},
					{
						img: require('@/assets/illustration-7.svg'),
						title: '实时翻译',
						tip: '我们的实时翻译技术让您瞬间理解聊天内容，无需等待或繁琐操作，即刻实现消息的实时翻译，为您的沟通提供无缝体验，使全球交流变得更容易。'
					},
					{
						img: require('@/assets/illustration-15.svg'),
						title: '隐私和安全',
						tip: '我们高度重视用户隐私，我们的聊天内容都会被储存在本地设备上，不会涉及第三方服务器或我们自己的服务器，从而确保您的聊天数据的安全性。'
					},
					{
						img: require('@/assets/illustration-8.svg'),
						title: '账号多开',
						tip: '我们支持同时激活多个不同平台账号，并且允许登录多个相同平台账号，通过底层优化，性能将翻倍提升，甚至老旧电脑也可以轻松实现多开功能。'
					},
					{
						img: require('@/assets/function6.png'),
						title: '多种接待方式',
						tip: '人工客服+机器人客服，匹配不同业务接待场景'
					},
					{
						img: require('@/assets/function7.png'),
						title: '支持窗口多样式',
						tip: '支持自动/邀请会话、窗口样式设置、个性化咨询图标等，提高沟通效率'
					},
					{
						img: require('@/assets/function8.png'),
						title: '防对话丢失',
						tip: '支持客服未及时回复自动退回机制，减少客服资源成本浪费'
					},
					{
						img: require('@/assets/function9.png'),
						title: '历史记录保存',
						tip: '客户会话/消息记录等，了解与客户的每一次沟通'
					},
					{
						img: require('@/assets/function10.png'),
						title: '数据统计分析',
						tip: '提供人工客服坐席工作量/服务质量/满意度等指标，为数据化管理提供支持和保障。'
					}
				],

			}
		},
		mounted() {},
		methods: {

		}
	}
</script>
