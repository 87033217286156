<style lang="less" scoped>
	.cont {
		width: 100%;
		z-index: 999;
		padding-bottom: 30px;

		.width1200 {
			margin: 0 auto;
		}

		.title {
			font-size: 40px;
			font-weight: bold;
			padding: 30px 0;
			text-align: center;
			width: 100%;
		}

		.list {
			display: flex;
			flex-wrap: wrap;

			.img {
				width: 200px;
				height: 60px;
			}

			.img:hover {
				animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			}

			@keyframes slide-top {
				0% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
				}

				100% {
					-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
				}
			}
		}
	}
</style>
<template>
	<div class="cont">
		<div class="width1200">
			<div class="title">支持平台</div>
			<div class="list">
				<div class="box" v-for="(item,index) in list" :key="index">
					<img class="img" :src="item" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [
					require('@/assets/WhatsApp.png'),
					require('@/assets/telegram.png'),
					require('@/assets/LINE.png'),
					require('@/assets/LINE-Business.png'),
					require('@/assets/LINE-WORKS.png'),
					require('@/assets/Skype-1.png'),
          require('@/assets/Instagram-1.png'),
          require('@/assets/Zalo-1.png'),
          require('@/assets/Messenger-1.png'),
          require('@/assets/twitter.png'),
          require('@/assets/TextNow.png'),
          require('@/assets/Google-Voice-1.png')

				],

			}
		},
		mounted() {},
		methods: {

		}
	}
</script>
